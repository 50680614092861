//引入axios 之前需要在main.ts中引入
import axios from "./Api"
import {ref} from 'vue'
export default {
    action: 'https://jk.disagree.top/api/BookMarks/BookMarks'
  };
const httpApi = ref("https://jk.disagree.top/api")

export const requestLogin = (parms:{}) => {
    axios.defaults.headers['Content-Type'] = 'application/json'
    return axios({
        url:httpApi.value + "/Login/GetJwtToken",
        method:'get',
        params:parms
    })
};


//查询所有书签C
export const GetAllBook = (parms:{})=>{
    axios.defaults.headers['Content-Type'] = 'application/json'
    return axios({
        url:httpApi.value + "/Bookmarks/GetPageList",
        method:'get',
        params:parms
    })
}

//初始化书签栏
export const GetBookmarks = (parms:{})=>{
    axios.defaults.headers['Content-Type'] = 'application/json'
    return axios.post(httpApi.value + "/Bookmarks/BookMarks",parms);
}

//查询置顶书签 默认只能置顶12个书签 刚好一页
export const GetTopBookmarks = () =>{
    return axios({
        url:httpApi.value + "/Bookmarks/GetAllTop",
        method:'get',
    })
}

//设置置顶
export const SetTopBookMark = (parms:{})=>{
    return axios({
        url:httpApi.value + "/Bookmarks/SetTopBookMark/"+parms,
        method:'get',
    })
}
//取消置顶
export const CancelTopBookMark = (parms:{})=>{
    return axios({
        url:httpApi.value + "/Bookmarks/CancelTopBookMark/"+parms,
        method:'delete',
    })
}