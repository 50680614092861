<template>
  <div class="login-container">
    <div class="login-form-container">
      <h2 class="login-title">回首</h2>
      <form @submit.prevent="login" class="login-form">
        <div class="form-group">
          <label for="username" class="form-label">用户名:</label>
          <el-input type="text" id="username" v-model="username" class="form-input" required></el-input>
        </div>
        <div class="form-group">
          <label for="password" class="form-label">密码:</label>
          <el-input type="password" id="password" v-model="password" class="form-input" required></el-input>
        </div>
        <el-button type="primary" native-type="submit" class="login-button">登陆</el-button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { requestLogin } from '../http/index';
import { ElMessageBox } from 'element-plus';
import { useRouter } from 'vue-router'; // 导入 useRouter 方法
export default{
  setup() {
  const router = useRouter(); // 创建 router 实例

  const username = ref('');
  const password = ref('');

  const login = () => {
    requestLogin({ name: username.value, pass: password.value })
      .then(response => {
        // 处理登录成功的逻辑
        if (response.data.status == 200) {
          console.log(response.data.response.token)
          localStorage.setItem('token', response.data.response.token);
          console.log(localStorage.getItem("token"))
          router.push('/home');
        } else {
          ElMessageBox.alert(response.data.msg, 'Error', {
            type: 'error'
          });
        }
      })
      .catch(error => {
        console.error('Login failed:', error);
        ElMessageBox.alert('Login failed', 'Error', {
          type: 'error'
        });
      });
  };

  return { username, password, login };
}

};

</script>

<style scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form-container {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.login-title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  font-size: 16px;
}

.form-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
}
</style>
