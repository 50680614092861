<template>
    <div class="iframe-container">
      <iframe
        src="https://chat.openai.com/c/9ea80f99-71dc-442c-8a8e-24d9db4b68b1"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    </div>
  </template>
  
  <style>
  .iframe-container {
    width: 100%;
    height: 600px; /* 设置嵌入的网页高度 */
  }
  </style>
  