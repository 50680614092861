import axios from 'axios';
// 请求延时
axios.defaults.timeout = 20000


axios.interceptors.request.use(
    config => {
        let token = localStorage.getItem("token");
        config.headers.Authorization = "Bearer " + token;
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);


export default axios;